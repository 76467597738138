<template>
  <div class="about">
    Logs
    <p>Token : {{getToken}} <a href="#" @click.prevent="logout" class="btn btn-hero-info">Reset token</a></p>

    
    <p>Nb sav à synchro : {{tosyncsInter.length}}</p>
    
    <p>SAV : </p>
    <ul>
      <li v-for="inter in getAllInter">
          {{inter}} <a href="#" class="btn btn-hero-danger" @click.prevent="deleteInter(inter.uid)">X</a>
      </li>
    </ul>
    <br>
    <p>Nb photo : </p>
    <ul>
      <li v-for="nameofpicutues in getAll()">
        <ul>
          <li v-for="picture in nameofpicutues">
              {{picture}} <a href="#"  class="btn btn-hero-danger" @click.prevent="deletepicture(picture)">X</a>
          </li>
        </ul>
      </li>
    </ul>
    
    <a href="#" @click.prevent="pushAllIntervention()" class="btn btn-hero-success">Push sav</a>
    <br>
    <a href="#" @click.prevent="pushAllPictures()" class="btn btn-hero-success">Push pictures</a>
    <br>
    <a href="#" @click.prevent="refreshconfig()" class="btn btn-hero-success">Refresh Config</a>



  </div>
</template>


<script>

  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'logs',
    methods: {
      ...mapActions('interventions', {'pushAllIntervention': 'pushAll', 'deleteInter': 'delete'}),
      ...mapActions('pictures', {'pushAllPictures': 'sync', 'deletepicture': 'delete'}),
      ...mapActions('login', ['logout']),
      ...mapActions('config', { 'refreshconfig': 'refresh'}),
    },
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters('login',[
        'getToken',
      ]),
      ...mapGetters('interventions',{'getAllInter': 'getAll', 'tosyncsInter': 'tosyncs'}),
      ...mapGetters('pictures',[
        'getAll',
      ]),
    },
    created(){
      this.$store.dispatch('pictures/loadall');

    },
  }
</script>
